import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Wavedropper LTD - Empowering Your Digital Presence with Precision
			</title>
			<meta name={"description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:title"} content={"Wavedropper LTD - Empowering Your Digital Presence with Precision"} />
			<meta property={"og:description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/anim-optimized.gif?v=2024-07-04T11:36:37.486Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.TopNavBar />
		<Section
			min-height="100vh"
			background="url(https://images.unsplash.com/photo-1610337673044-720471f83677?auto=format&fit=crop&w=1500&q=80) center center/cover no-repeat"
			md-background="url(https://images.unsplash.com/photo-1610337673044-720471f83677?auto=format&fit=crop&w=800&q=80) center center/cover no-repeat"
			padding="88px 0 88px 0"
			quarkly-title="404-7"
		>
			<Override slot="SectionContent" justify-content="center" max-width="1220px" align-items="center" />
			<Box
				border-radius="24px"
				display="flex"
				justify-content="center"
				padding="56px 106px 64px 106px"
				background="--color-dark"
				max-width="600px"
				sm-padding="26px 30px 34px 30px"
				align-items="center"
				flex-direction="column"
			>
				<Text font="--headline1" color="--light" margin="0px 0px 16px 0px" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					404
				</Text>
				<Text
					md-width="100%"
					font="--lead"
					color="--lightD1"
					margin="0px 0px 40px 0px"
					text-align="center"
					width="100%"
					lg-width="80%"
				>
					The page you are looking for is not available or doesn’t belong to this website!
				</Text>
				<Link
					font="--lead"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					background="--color-light"
					href="#"
					text-decoration-line="initial"
					color="--dark"
				>
					Go Back to Home
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 50px 0" quarkly-title="Footer-17" id="footer" background="#000000">
			<Override slot="SectionContent" justify-content="flex-start" />
			<Box
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				sm-flex-direction="column"
				sm-padding="0 0px 0 0px"
			>
				<Box
					lg-margin="0px 0px 0px 0px"
					width="50%"
					display="flex"
					sm-width="100%"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					lg-padding="0px 0 0px 0px"
					sm-margin="0px 0px 35px 0px"
					font="--base"
				>
					<Text
						margin="0 0px 35px 0px"
						font="--base"
						color="#c3c8d0"
						sm-text-align="left"
						sm-margin="0 0px 0 0px"
						lg-max-width="640px"
					>
						We're focusing on creating web applications and interactive tools. Services include developing educational apps and custom web solutions to enhance online engagement, with a focus on supporting startups and businesses in establishing their online presence.
					</Text>
				</Box>
				<Components.WavedropperSignature />
				<Box
					min-width="100px"
					min-height="100px"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="54px"
					lg-align-items="start"
					md-grid-template-columns="repeat(3, 1fr)"
					md-grid-gap="36px 34px"
					sm-grid-template-columns="1fr"
					sm-grid-gap="16px 0"
					justify-content="flex-end"
					sm-flex-direction="column"
					display="none"
					lg-justify-content="flex-start"
				>
					<Box
						align-items="flex-start"
						lg-align-items="flex-start"
						justify-content="flex-start"
						display="grid"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						margin="0px 50px 0px 0px"
						align-content="start"
						grid-gap="10px 0"
					>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="#c3c8d0"
							hover-color="#ffffff"
							letter-spacing="1px"
						>
							PRICING
						</Link>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="#c3c8d0"
							hover-color="#ffffff"
							letter-spacing="1px"
						>
							DOCUMENTATION
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#ffffff"
							href="#"
							text-decoration-line="initial"
							color="#c3c8d0"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
							letter-spacing="1px"
						>
							GUIDES
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#ffffff"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="#c3c8d0"
							display="flex"
							letter-spacing="1px"
						>
							API STATUS
						</Link>
					</Box>
					<Box
						align-items="flex-start"
						margin="0px 0px 0px 0"
						lg-align-items="flex-start"
						justify-content="flex-start"
						display="grid"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						align-content="start"
						grid-gap="10px 0"
					>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="#c3c8d0"
							hover-color="#ffffff"
							letter-spacing="1px"
						>
							ABOUT
						</Link>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="#c3c8d0"
							hover-color="#ffffff"
							letter-spacing="1px"
						>
							BLOG
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#ffffff"
							href="#"
							text-decoration-line="initial"
							color="#c3c8d0"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
							letter-spacing="1px"
						>
							JOBS
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#ffffff"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="#c3c8d0"
							display="flex"
							letter-spacing="1px"
						>
							PARTNERS
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				justify-content="space-between"
				padding="50px 0px 0px 0px"
				border-color="#232a44"
				md-padding="30px 0px 0px 0px"
				md-flex-direction="column"
				lg-padding="40px 0px 0px 0px"
				align-items="flex-end"
				height="auto"
				md-align-items="flex-start"
				font="--base"
			>
				<Text margin="0px 0px 0px 0px" font="--base" color="#c3c8d0" md-margin="0px 0px 25px 0px">
					© 2024 WAVEDROPPER LTD. All rights reserved.
				</Text>
				<Box display="none" grid-template-columns="repeat(5, 1fr)" grid-gap="16px 24px" md-align-self="flex-start">
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaTwitterSquare}
							size="24px"
							color="#c3c8d0"
							hover-color="white"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaGithub}
							size="24px"
							color="#c3c8d0"
							hover-color="white"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaYoutube}
							size="24px"
							color="#c3c8d0"
							hover-color="white"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65e4858a867c86001f1cafaf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});